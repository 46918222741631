/* eslint-disable max-len */
import type { FC } from 'react';
import Head from 'next/head';

export interface HtmlHeadProps {
  title: string;
  description: string;
  url: string;
}

export const HtmlHead: FC<HtmlHeadProps> = (props) => {
  const {
    title,
    url,
    description = 'Crown Town Cakes specializes in made-to-order custom cakes for any occasion. Crown Town Cakes services Palm Springs, California and surrounding areas.',
  } = props;
  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" href="/favicon.ico" />
      <link rel="canonical" href={url} />
      <link rel="preconnect" href="https://images.ctfassets.net" />
      <meta property="og:site_name" content={'Crown Town Cakes'} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={
          'https://images.ctfassets.net/g67phlm0yctp/4B17oSmlO6a5Nc2d68ZL98/4b847f687ad79fb1ebfcecfaab7ee8f9/crown-town-cakes.svg'
        }
      />
      <meta property="og:image:width" content="150" />
      <meta property="og:image:height" content="150" />
      <link
        rel="image_src"
        href={
          'https://images.ctfassets.net/g67phlm0yctp/4B17oSmlO6a5Nc2d68ZL98/4b847f687ad79fb1ebfcecfaab7ee8f9/crown-town-cakes.svg'
        }
      />
      <meta name="twitter:title" content={'Crown Town Cakes'} />
      <meta
        name="twitter:image"
        content={
          'https://images.ctfassets.net/g67phlm0yctp/4B17oSmlO6a5Nc2d68ZL98/4b847f687ad79fb1ebfcecfaab7ee8f9/crown-town-cakes.svg'
        }
      />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:description" content={description} />
      <meta name="description" content={description} />
    </Head>
  );
};
