import type { FC } from 'react';
import Image, { ImageLoader, ImageProps } from 'next/image';
import { ImageData } from '@Lib/types/design-types';
import { blurDataUrl } from '@Lib/utils/placeholder';

export type ContentfulNextImageProps = Omit<ImageProps, 'alt' | 'src'> & {
  image: ImageData;
  type: 'gallery' | 'hero' | 'post';
};

const galleryLoader: ImageLoader = (p) => {
  const params = new URLSearchParams([
    ['fm', 'avif'],
    ['w', Math.min(p.width, 1080).toString()],
  ]);
  return `${p.src}?${params.toString()}`;
};

const heroLoader: ImageLoader = (p) => {
  const params = new URLSearchParams([
    ['fm', 'avif'],
    ['w', Math.min(p.width, 1080).toString()],
  ]);
  return `${p.src}?${params.toString()}`;
};

const heroSizes = [
  '(min-width: 640px) 100vw',
  '(min-width: 768px) 100vw',
  '(min-width: 1024px) 100vw',
  '(min-width: 1280px) 100vw',
  '(min-width: 1536px) 100vw',
].join(',');

const gallerySizes = `
(max-width: 640px) 100vw,
(max-width: 768px) 100vw,
(max-width: 1024px) 50vw,
(max-width: 1280px) 50vw,
(max-width: 1536px) 50vw,
25vw`;

const postSizes = `
(max-width: 640px) 75vw,
(max-width: 768px) 75vw,
(max-width: 1024px) 75vw,
(max-width: 1280px) 75vw,
(max-width: 1536px) 75vw,
100vw`;

export const ContentfulNextImage: FC<ContentfulNextImageProps> = (props) => {
  const { image, sizes, type, ...nextImageProps } = props;
  const nextSizes =
    type === 'gallery' ? gallerySizes : type === 'hero' ? heroSizes : postSizes;
  const nextLoader =
    type === 'gallery'
      ? galleryLoader
      : type === 'hero'
      ? heroLoader
      : galleryLoader;

  return (
    <Image
      {...nextImageProps}
      src={image.src}
      alt={image.alt || ''}
      placeholder="blur"
      blurDataURL={blurDataUrl}
      fill
      sizes={nextSizes}
      loader={nextLoader}
    />
  );
};
